import React from "react";
// import AboutBackground from "../assets/img/about-background-image.png";
import Skiza from "../assets/img/skiza3.png";
// import { BsFillPlayCircleFill } from "react-icons/bs";

export const About = () => {
  return (
    <div className="about-section-container" id="skills">
      <div className="about-background-image-container">
        {/* <img src={AboutBackground} alt="" /> */}
      </div>
      <div className="about-section-image-container">
        <img src={Skiza} alt="" />
      </div>
      <div className="about-section-text-container">
        <p className="primary-subheading">About</p>
        <h1 className="primary-heading">
        Zamsqure, Kenya's top spot for Islamic caller tunes.
        </h1>
        <p className="primary-text">
        We specialize in providing a wide range of Islamic nashids, Quran recitations, and dua recitations, curated from the finest scholars and artists. 
        </p>
        <p className="primary-text">
        Elevate your caller experience with the soothing sounds of faith and spirituality. Join us as we bring the essence of Islamic culture to every incoming call. Let your callers feel the serenity and tranquility with MySkiza. 
        Experience the difference today!
        </p>
        <div className="about-buttons-container">
          <button className="secondary-button">Learn More</button>
          <button className="watch-video-button">
            {/* <BsFillPlayCircleFill /> Watch Video */}
          </button>
        </div>
      </div>
    </div>
  )
}
