import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavBar } from "./components/NavBar";
import { Banner } from "./components/Banner";
import { About } from "./components/About";
import { Subscribe } from "./components/Subscribe";
import { Projects } from "./components/Projects";
import { Contact } from "./components/Contact";
import { Footer } from "./components/Footer";
import { useEffect } from 'react'; // Import useEffect
import logo from './assets/img/logo1.png'; // Import the logo from src

function App() {
  
  useEffect(() => {
    // Dynamically set the favicon
    const favicon = document.getElementById('favicon');
    if (favicon) {
      favicon.href = logo; // Set the href of the favicon to your logo in src
    }
  }, []); // Empty dependency array means this runs once when component mounts

  return (
    <div className="App">
      <NavBar />
      <Banner />
      <About />
      <Projects />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
