// import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
// import { ProjectCard } from "./ProjectCard";
// import projImg1 from "../assets/img/project-1.png";
// import projImg2 from "../assets/img/project-2.png";
// import projImg3 from "../assets/img/project-3.png";
// import colorSharp2 from "../assets/img/color-sharp2.png";
// import 'animate.css';
// import TrackVisibility from 'react-on-screen';

// export const Projects = () => {

//   const projects = [
//     {
//       title: "Business Startup",
//       description: "Design & Development",
//       imgUrl: projImg1,
//     },
//     {
//       title: "Business Startup",
//       description: "Design & Development",
//       imgUrl: projImg2,
//     },
//     {
//       title: "Business Startup",
//       description: "Design & Development",
//       imgUrl: projImg3,
//     },
//     {
//       title: "Business Startup",
//       description: "Design & Development",
//       imgUrl: projImg1,
//     },
//     {
//       title: "Business Startup",
//       description: "Design & Development",
//       imgUrl: projImg2,
//     },
//     {
//       title: "Business Startup",
//       description: "Design & Development",
//       imgUrl: projImg3,
//     },
//   ];

//   return (
//     <section className="project" id="projects">
//       <Container>
//         <Row>
//           <Col size={12}>
//             <TrackVisibility>
//               {({ isVisible }) =>
//               <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
//                 <h2>OUR PLAYLIST</h2>
//                 <p>Explore our curated playlist featuring the serene beauty of Quran recitations, the uplifting melodies of nasheeds, and the heartfelt sincerity of duas, Discover moments of peace, reflection, and connection.</p>
//                 <Tab.Container id="projects-tabs" defaultActiveKey="first">
//                   <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
//                     <Nav.Item>
//                       <Nav.Link eventKey="first">Quran Recitations</Nav.Link>
//                     </Nav.Item>
//                     <Nav.Item>
//                       <Nav.Link eventKey="second">Islamic Nasheeds</Nav.Link>
//                     </Nav.Item>
//                     <Nav.Item>
//                       <Nav.Link eventKey="third">Dua Recitations</Nav.Link>
//                     </Nav.Item>
//                   </Nav>
//                   <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
//                     <Tab.Pane eventKey="first">
//                       <Row>
//                         {
//                           projects.map((project, index) => {
//                             return (
//                               <ProjectCard
//                                 key={index}
//                                 {...project}
//                                 />
//                             )
//                           })
//                         }
//                       </Row>
//                     </Tab.Pane>
//                     <Tab.Pane eventKey="second">
//                       <Row>
//                         {
//                           projects.map((project, index) => {
//                             return (
//                               <ProjectCard
//                                 key={index}
//                                 {...project}
//                                 />
//                             )
//                           })
//                         }
//                       </Row>
//                     </Tab.Pane>
//                     <Tab.Pane eventKey="third">
//                       <Row>
//                         {
//                           projects.map((project, index) => {
//                             return (
//                               <ProjectCard
//                                 key={index}
//                                 {...project}
//                                 />
//                             )
//                           })
//                         }
//                       </Row>
//                     </Tab.Pane>
//                   </Tab.Content>
//                 </Tab.Container>
//               </div>}
//             </TrackVisibility>
//           </Col>
//         </Row>
//       </Container>
//       <img className="background-image-right" src={colorSharp2}></img>
//     </section>
//   )
// }
// Projects.js

import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import TrackVisibility from 'react-on-screen';
import colorSharp2 from "../assets/img/color-sharp2.png";

import projImg1 from "../assets/img/maher3.jpeg";
import projImg2 from "../assets/img/sudais3.jpeg";
import projImg3 from "../assets/img/idris3.jpeg";
// import projImg4 from "../assets/img/idris5.jpeg";
import audio1 from "../assets/img/Audio1.mp4";
import audio2 from "../assets/img/Audio2.mp4";
import audio3 from "../assets/img/Audio3.mp4";
import audio4 from "../assets/img/Audio4.mp4";
import audio5 from "../assets/img/Audio5.mp4";
import audio6 from "../assets/img/Audio6.mp4";
import audio7 from "../assets/img/Audio7.mp4";
import audio8 from "../assets/img/Audio8.mp4";
import audio9 from "../assets/img/Audio9.mp4";
import audio10 from "../assets/img/Audio10.mp4";
import audio11 from "../assets/img/Audio11.mp4";

export const Projects = () => {
  const quranRecitations = [
    {
      imgUrl: projImg3,
      audioUrl: audio1,
      audioDescription: "Sheikh Mohamed - Dial *812*862#",
      dialCode: "*812*862#"
    },
    {
      imgUrl: projImg3,
      audioUrl: audio2,
      audioDescription: "Sheikh Mohamed - Dial *812*863#",
      dialCode: "*812*863#"
    },
    {
      imgUrl: projImg3,
      audioUrl: audio3,
      audioDescription: "Sheikh Mohamed - Dial *812*863#",
      dialCode: "*812*863#"
    },
    {
      imgUrl: projImg3,
      audioUrl: audio7,
      audioDescription: "Sheikh Mohamed - Dial *812*868#",
      dialCode: "*812*868#"
    },
    {
      imgUrl: projImg3,
      audioUrl: audio4,
      audioDescription: "Sheikh Mohamed - Dial *812*865#",
      dialCode: "*812*865#"
    },
  ];

  const islamicNasheeds = [
    {
      imgUrl: projImg1,
      audioUrl: audio5,
      audioDescription: "Maher Zain - Dial *812*866#",
      dialCode: "*812*866#"
    },
    {
      imgUrl: projImg1,
      audioUrl: audio6,
      audioDescription: "Maher Zain - Dial *812*867#",
      dialCode: "*812*867#"
    },
    {
      imgUrl: projImg1,
      audioUrl: audio11,
      audioDescription: "Maher Zain - Dial *812*872#",
      dialCode: "*812*872#"
    },
  ];

  const duaRecitations = [
    {
      imgUrl: projImg2,
      audioUrl: audio8,
      audioDescription: "Sheikh Sudais - Dial *812*869#",
      dialCode: "*811*869#"
    },
    {
      imgUrl: projImg2,
      audioUrl: audio9,
      audioDescription: "Sheikh Sudais - Dial *812*870#",
      dialCode: "*812*870#"
    },
    {
      imgUrl: projImg2,
      audioUrl: audio10,
      audioDescription: "Sheikh Sudais - Dial *812*871#",
      dialCode: "*812*871#"
    },
  ];

  return (
    <section className="project" id="projects">
      <Container>
        <Row className="project-row">
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                  <h2>OUR PLAYLIST</h2>
                  <p>Explore our curated playlist featuring the serene beauty of Quran recitations, the uplifting melodies of nasheeds, and the heartfelt sincerity of duas. Discover moments of peace, by Clicking the Download Icon to Subscribe.</p>
                  <Tab.Container id="projects-tabs" defaultActiveKey="first">
                    <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                      <Nav.Item>
                        <Nav.Link eventKey="first">Quran Recitations</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second">Islamic Nasheeds</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="third">Dua Recitations</Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                      <Tab.Pane eventKey="first">
                        <Row className="project-row">
                          {quranRecitations.map((project, index) => (
                            <ProjectCard key={index} {...project} />
                          ))}
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <Row className="project-row">
                          {islamicNasheeds.map((project, index) => (
                            <ProjectCard key={index} {...project} />
                          ))}
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="third">
                        <Row className="project-row">
                          {duaRecitations.map((project, index) => (
                            <ProjectCard key={index} {...project} />
                          ))}
                        </Row>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              }
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2} alt="Background" />
    </section>
  );
};
