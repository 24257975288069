import { useState, useRef, useEffect } from "react";
import { Col } from "react-bootstrap";
import { FaPlay, FaPause, FaDownload } from "react-icons/fa";

export const ProjectCard = ({ title, description, imgUrl, audioUrl, audioDescription, dialCode }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const audioRef = useRef(new Audio(audioUrl));

  useEffect(() => {
    const audio = audioRef.current;

    const handleLoadedMetadata = () => {
      setDuration(audio.duration);
    };

    const handleTimeUpdate = () => {
      setCurrentTime(audio.currentTime);
    };

    audio.addEventListener("loadedmetadata", handleLoadedMetadata);
    audio.addEventListener("timeupdate", handleTimeUpdate);

    return () => {
      audio.removeEventListener("loadedmetadata", handleLoadedMetadata);
      audio.removeEventListener("timeupdate", handleTimeUpdate);
    };
  }, []);

  const togglePlayPause = () => {
    const audio = audioRef.current;
    if (isPlaying) {
      audio.pause();
    } else {
      audio.play();
    }
    setIsPlaying(!isPlaying);
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
  };

  const handleProgressClick = (event) => {
    const progressBar = event.target;
    const newTime = (event.clientX - progressBar.getBoundingClientRect().left) / progressBar.offsetWidth * duration;
    audioRef.current.currentTime = newTime;
    setCurrentTime(newTime);
  };

  return (
    <Col size={12} xs={12} sm={6} md={4}>
      <div className="proj-imgbx">
        <div className="img-wrapper">
          <img src={imgUrl} alt={title} />
        </div>
        <div className="play-btn" onClick={togglePlayPause}>
          {isPlaying ? <FaPause /> : <FaPlay />}
        </div>
        <div className="audio-controls">
          <div className="audio-time current-time">{formatTime(currentTime)}</div>
          <div className="audio-progress" onClick={handleProgressClick}>
            <div className="progress-bar" style={{ width: `${(currentTime / duration) * 100}%` }}></div>
          </div>
          <div className="audio-time duration">{formatTime(duration)}</div>
        </div>
        <div className="proj-txtx">
          <h4>{title}</h4>
          <span>{description}</span>
        </div>
      </div>
      <div className="proj-extra">
        <a href={`tel:${dialCode}`} className="download-icon">
          <FaDownload />
        </a>
        <p className="audio-description">{audioDescription}</p>
      </div>
      <style jsx>{`
  .proj-imgbx {
    position: relative;
    border-radius: 22px;
    overflow: hidden;
    height: 70%;
  }

  .img-wrapper {
    position: relative;
    width: 100%;
  }

  .img-wrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .play-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.5);
    padding: 15px;
    cursor: pointer;
    color: white;
    font-size: 24px;
  }

  .audio-controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    bottom: 40px;
    width: 90%;
    left: 5%;
    margin-top: 10px;
  }

  .audio-time {
    color: white;
    font-size: 14px;
    background: rgba(0, 0, 0, 0.5);
    padding: 5px 10px;
    border-radius: 10px;
  }

  .audio-progress {
    flex-grow: 1;
    height: 10px;
    background: rgba(255, 255, 255, 0.5);
    margin: 0 10px;
    cursor: pointer;
    position: relative;
  }

  .progress-bar {
    height: 100%;
    background: #007bff;
  }

  .proj-txtx {
    position: relative;
    padding: 20px;
    color: white;
    text-align: center;
  }

  .proj-extra {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Spread the download icon and description */
  padding: 10px 20px;
  margin-top: -20px;
  flex-wrap: wrap; /* This ensures it wraps properly on smaller screens */
}

  .download-icon {
    color: black;
    font-size: 25px;
    margin-right: 10px;
  }

 .audio-description {
  font-size: 14px;
  color: white;
  white-space: normal; /* Ensure the text wraps instead of overflowing */
  word-wrap: break-word; /* Prevent long words from overflowing */
  width: 100%; /* Ensure the description fits within the card */
  text-align: left;
}

  /* Media Query for Tablets (768px) */
  @media (max-width: 768px) {
    .audio-controls {
      bottom: 0px; /* Adjust position to keep inside the image */
      width: 80%; /* Adjust width */
      left: 10%; /* Recenter within the image */
    }

    .audio-progress {
      height: 8px; /* Reduce height */
    }

    .audio-time {
      display:none;
    }

    .proj-extra {
    flex-direction: row; /* Stack the icon and description side by side */
    justify-content: space-between; /* Spread them evenly */
    padding: 5px 15px; /* Adjust padding */
    margin-top: -60px; /* Slightly reduce the gap */
  }

     .download-icon {
    font-size: 20px; /* Slightly smaller icon */
  }

     .audio-description {
    font-size: 13px; /* Slightly smaller font */
    text-align: center; /* Align text centrally */
  }
  }
  @media (max-width: 540px) {
  .proj-extra {
    flex-direction: column; /* Stack the icon and description vertically */
    align-items: center;
    padding: 10px 0;
    margin-top: -40px; /* Further reduce the gap */
  }

  .download-icon {
    font-size: 18px; /* Make the icon smaller */
    margin-bottom: 5px; /* Add space below the icon */
  }

  .audio-description {
    font-size: 12px; /* Make the text smaller */
    text-align: center; /* Align text centrally */
    word-wrap: break-word; /* Break long words */
  }
}
`}</style>

    </Col>
  );
};
